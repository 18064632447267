import { ParsedUrlQuery } from 'querystring';
import getConfig from 'next/config';
import accounting from 'accounting';
import { Dispatch } from 'redux';
import {
    CURRENCIES,
    DISABLE_BANK_OFFERS_TIME_IN_DAYS,
    EN_RU_BINDING,
    FORM_FIELDS,
    FORM_NAME,
    INT_ADS_FB_MAP,
    INT_MEDIUM_VALUES,
    RU_EN_BINDING,
    STATUS_STATES,
    SUPER_RELEVANT_LENGTH,
} from '../constants';
import { ILocation } from '../store/reducers/locations';
import { IState as FbStatues } from '../store/reducers/fbStatuses';
import { OfferItems } from '../components/offers.new/types';
import { CreditProduct } from '../services/creditProduct/types';
import { sortBySuperPositionManual } from '../components/offers.new/helpers/offers/groups/sorting';
import { periodToNumber, dateStringToLocalTs } from './dates';
import { Suitable, Unsuitable, FromTo } from 'services/offers/offers.types';

export * from './addresses';
export * from './dates';

accounting.settings.number = {
    precision: 2,
    decimal: ',',
    thousand: ' ',
};

type ScrollType = 'smooth' | 'auto';

export const scrollToForm = (scrollType: ScrollType = 'smooth') => {
    if (typeof window !== 'undefined') {
        scrollTo(window.document.getElementById(FORM_NAME), scrollType);
    }
};

export const scrollToWindowTop = (scrollType: ScrollType = 'smooth') => {
    if (typeof window !== 'undefined') {
        scrollTo(window.document.body, scrollType);
    }
};

export const scrollToElementById = (
    id: string,
    scrollType: ScrollType = 'smooth',
    scrollPosition: ScrollLogicalPosition = 'start',
) => {
    if (typeof window !== 'undefined') {
        scrollTo(window.document.getElementById(id), scrollType, scrollPosition);
    }
};

export const scrollTo = (
    element?: Element | null,
    scrollType: ScrollType = 'smooth',
    scrollPosition: ScrollLogicalPosition = 'start',
) => {
    if (element && element.scrollIntoView) {
        element.scrollIntoView({ behavior: scrollType, block: scrollPosition });
    }
};

export const getGlobalRegionFormFields = (globalRegion: ILocation | null) => {
    return globalRegion
        ? {
              [FORM_FIELDS.REGION]: globalRegion.name,
              [FORM_FIELDS.REGION_ID]: globalRegion.id,
              [FORM_FIELDS.REGION_LOCATION_ROUTE]: globalRegion.route,
              [FORM_FIELDS.REGION_FULL_NAME]: globalRegion.fullName,
          }
        : {};
};

export const getRegionId = (region: ILocation): string => {
    const { id } = region;
    return String(id);
    // const splitRoute = route.split('.');
    // return splitRoute.length > 1 ? splitRoute[1] : splitRoute[0];
};

interface AnimateProps {
    duration?: number;
    timing?: (fraction: number) => number;
    draw: (progress: number) => void;
}

export const animate = (props: AnimateProps) => {
    const { duration = 700, timing = (f: number) => f, draw } = props;
    const start = performance.now();
    requestAnimationFrame(function a(time) {
        let timeFraction = (time - start) / duration;
        if (timeFraction > 1) {
            timeFraction = 1;
        }
        draw(timing(timeFraction));
        if (timeFraction < 1) {
            requestAnimationFrame(a);
        }
    });
};

export const mapEnToRu = (string: string) =>
    string.replace(/[\D]/g, (letter) => {
        const ruLetter = EN_RU_BINDING[letter.toLowerCase()];
        if (!ruLetter) {
            return letter;
        }
        return letter.toLowerCase() === letter ? ruLetter : ruLetter.toUpperCase();
    });

export const mapRuToEng = (string: string) =>
    string.replace(/[\D]/g, (letter) => {
        const ruLetter = RU_EN_BINDING[letter.toLowerCase()];
        if (!ruLetter) {
            return letter;
        }
        return letter.toLowerCase() === letter ? ruLetter : ruLetter.toUpperCase();
    });

export const createCountDown = (
    seconds: number,
    step = 1,
    onTick: (left: number) => void,
    onEnd: (left: number) => void,
) => {
    let left = seconds;
    let interval: number;

    const end = () => {
        if (interval) {
            clearInterval(interval);
            interval = 0;
        }
        onEnd(left);
    };

    const tick = () => (--left ? onTick(left) : end());
    setTimeout(tick, 0);
    interval = setInterval(tick, step * 1000);

    return end;
};

export const capitalize = (text = '', decapitalizeOther = true) =>
    text.substr(0, 1).toUpperCase() + (decapitalizeOther ? text.substr(1).toLowerCase() : text.substr(1));

export const decapitalize = (text = '') => text.substr(0, 1).toLowerCase() + text.substr(1);

export const pluralize = (n: number, one: string, few: string, many: string): string => {
    const nP100 = n % 100;
    const nP10 = n % 10;

    if (nP10 === 1 && nP100 !== 11) {
        return one;
    }
    if (nP10 >= 2 && nP10 <= 4 && (nP100 < 10 || nP100 >= 20)) {
        return few;
    }
    return many;
};

export const pluralizeDays = (days: number, isMany = false) =>
    // @ts-ignore
    pluralize(days, ...['день', 'дня', 'дней', 'дней'].slice(isMany ? 1 : 0));

export const pluralizeMonths = (months: number, isMany = false) =>
    // @ts-ignore
    pluralize(months, ...['месяц', 'месяца', 'месяцев', 'месяцев'].slice(isMany ? 1 : 0));

export const pluralizeYears = (years: number, isMany = false) =>
    // @ts-ignore
    pluralize(years, ...['год', 'года', 'лет', 'лет'].slice(isMany ? 1 : 0));

export const pluralizeOffers = (offers: number, isMany = false) =>
    // @ts-ignore
    pluralize(offers, ...['предложение', 'предложения', 'предложения', 'предложений'].slice(isMany ? 1 : 0));

export const pluralizePeriod = (period: number) => {
    const years = Math.trunc(period / 12);
    const months = period % 12;
    const yearsPart = years ? `${years} ${pluralizeYears(years)}` : '';
    const monthsPart = months ? `${months} ${pluralizeMonths(months)}` : '';
    return [yearsPart, monthsPart].filter((s) => s).join(' ');
};

export const formatCurrency = (number: number, isFraction = false) => {
    const config: Intl.NumberFormatOptions = {
        style: 'currency',
        currency: 'RUB',
        minimumFractionDigits: 0,
        maximumFractionDigits: isFraction ? 2 : 0,
    };

    return new Intl.NumberFormat('ru-RU', config).format(number);
};

export const formatPercent = (number: number, fractionDigits = 2) =>
    new Intl.NumberFormat('ru-RU', {
        style: 'percent',
        maximumFractionDigits: fractionDigits,
    })
        .format(number / 100)
        .replace('.', ',');

export const pluralizeUnit = (value: string): string => {
    const number = periodToNumber(value, value.includes('D') ? 'days' : undefined);

    if (value.includes('D')) {
        return `${number} ${pluralizeDays(number)}`;
    }

    return number % 12 === 0 ? `${number / 12} ${pluralizeYears(number / 12)}` : `${number} ${pluralizeMonths(number)}`;
};

export const format = (n: number, precision = 0) => accounting.formatNumber(n, precision);

interface Source {
    [key: string]: FE.FieldValue;
}

export const sourceNameByValue = (
    value: FE.FieldValue,
    source: Array<Source>,
    nameKey: keyof Source = 'name',
    valueKey: keyof Source = 'value',
) => {
    const src = source.find((s) => s[valueKey] === value);
    if (src) {
        return src[nameKey];
    }
    return value;
};

export const sourceValueByName = (
    name: string,
    source: Array<Source>,
    nameKey: keyof Source = 'name',
    valueKey: keyof Source = 'value',
) => {
    const src = source.find((s) => s[nameKey] === name);
    if (src) {
        return src[valueKey];
    }
    return name;
};

export const sourceValueByCode = (
    code: string,
    source: Array<Source>,
    nameKey: keyof Source = 'code',
    valueKey: keyof Source = 'value',
) => {
    const src = source.find((s) => s[nameKey] === code);
    if (src) {
        return src[valueKey];
    }
    return source.find((s) => s[nameKey] === '0')?.[valueKey];
};

export const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const omit = <T extends { [key: string]: any }, K extends Array<keyof T>>(o: T, list: K): Omit<T, K[number]> => {
    const omitted = {} as Omit<T, K[number]>;
    for (const key of Object.keys(o)) {
        if (!list.includes(key)) {
            omitted[key as keyof typeof omitted] = o[key];
        }
    }
    return omitted;
};

export const pick = <T extends { [key: string]: any }, K extends Array<keyof T>>(o: T, list: K): Pick<T, K[number]> => {
    const picked = {} as Pick<T, K[number]>;
    for (const key of Object.keys(o)) {
        if (list.includes(key)) {
            picked[key as keyof T] = o[key];
        }
    }
    return picked;
};

export const flatten = <T>(arr: Array<Array<T>>) => arr?.reduce((acc, item) => [...acc, ...item], []);

export const capitalizeFirstLetter = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

export const amountRangeText = ({ from, to }: FromTo, prefix = true, symbol = CURRENCIES.RUB.SYMBOL_ALTERNATIVE) => {
    let text = '';

    if (to && from && to !== from) {
        text = `${format(from)} - ${format(to)} ${symbol}`;
    } else if (from || to) {
        text = `${format(from || to)} ${symbol}`;
    }

    if (prefix) {
        if (to && from && to < from) {
            text = `от ${text}`;
        } else if (to && !from) {
            text = `до ${text}`;
        }
    }

    return text.trim();
};

export const getSuperRelevant = (
    suitableOffers: Array<Suitable>,
    q = Infinity,
    fbStatuses?: FbStatues,
    activeOffers?: Array<Suitable>,
): Array<Suitable> => {
    const bankIds = new Set<string>();
    const topOffers = suitableOffers
        .filter(
            (item) =>
                typeof item.advertising.superPositionManual === 'number' &&
                !isNaN(item.advertising.superPositionManual as number) &&
                item.advertising.superPositionManual !== 0,
        )
        .filter((item) => item.category !== 'MicroCredit')
        .sort(sortBySuperPositionManual())
        .filter((item) => {
            const exists = bankIds.has(item.organization.id);
            bankIds.add(item.organization.id);
            return !exists;
        })
        .slice(0, q);

    if (topOffers.length < SUPER_RELEVANT_LENGTH.MIN) {
        return [];
    }

    if (!fbStatuses) {
        return topOffers;
    }

    const superRelevantWithBanksActive = topOffers.filter((o) => fbStatuses.bankStatuses[o.organization.id]);
    const superRelevantActive = (activeOffers || []).filter((o) => o.advertising.superPositionManual);

    if (superRelevantActive.length >= q || superRelevantWithBanksActive.length >= q) {
        return [];
    }

    return topOffers;
};

export const getLinkFor = (type: 'profile') => {
    switch (type) {
        case 'profile':
            return `${getConfig().publicRuntimeConfig.issuer}/user/profile`;

        default:
            return '';
    }
};

export const getUrlGuid = (url?: string): string => {
    if (!url) {
        return 'none';
    }
    return (
        url
            .split('?')[0]
            .split('/')
            .filter((n) => n.trim())
            .pop() || 'none'
    );
};

export const isWebView = (userAgent: string) => {
    const rules = [
        // if it says it's a webview, let's go with that
        'WebView',
        // iOS webview will be the same as safari but missing "Safari"
        '(iPhone|iPod|iPad)(?!.*Safari)',
        // Android Lollipop and Above: webview will be the same as native but it will contain "wv"
        // Android KitKat to lollipop webview will put {version}.0.0.0
        'Android.*(wv|.0.0.0)',
        // old chrome android webview agent
        'Linux; U; Android',
    ];
    const webviewRegExp = new RegExp('(' + rules.join('|') + ')', 'ig');
    return !!userAgent.match(webviewRegExp);
};

export const isIphoneOrIpad = (userAgent: string) => {
    const rules = ['iPhone|iPod|iPad'];
    const webviewRegExp = new RegExp('(' + rules.join('|') + ')', 'ig');
    return !!userAgent.match(webviewRegExp);
};

export const parseIntAdsCookie = (cookieValue?: string) => {
    if (cookieValue) {
        const parsed = cookieValue.split('|').reduce((output, item) => {
            const [name, value] = item.split('=');
            if (value) {
                output[name as keyof typeof INT_ADS_FB_MAP] = value;
            }
            return output;
        }, {} as Partial<Record<keyof typeof INT_ADS_FB_MAP, string>>);

        return Object.keys(parsed).reduce((output, key) => {
            output[INT_ADS_FB_MAP[key as keyof typeof INT_ADS_FB_MAP] || key] =
                parsed[key as keyof typeof INT_ADS_FB_MAP];
            return output;
        }, {} as Partial<Record<ValuesOf<typeof INT_ADS_FB_MAP>, string>>);
    }

    return {};
};

export const parseIntCrossFromCookieOrQuery = (cookieValue?: string, query?: ParsedUrlQuery) => {
    if (query && query['int_medium'] === INT_MEDIUM_VALUES.CROSS) {
        return Object.entries(query).reduce((output, [key, value]) => {
            if (value && typeof value === 'string' && {}.hasOwnProperty.call(INT_ADS_FB_MAP, key)) {
                output[key as keyof typeof INT_ADS_FB_MAP] = value;
            }
            return output;
        }, {} as Partial<Record<keyof typeof INT_ADS_FB_MAP, string>>);
    }

    if (cookieValue) {
        return cookieValue.split(/\|?int_/).reduce((output, item) => {
            const [name, value] = item.split('=');
            if (value && value !== '(not set)' && {}.hasOwnProperty.call(INT_ADS_FB_MAP, 'int_' + name)) {
                output[('int_' + name) as keyof typeof INT_ADS_FB_MAP] = value;
            }
            return output;
        }, {} as Partial<Record<keyof typeof INT_ADS_FB_MAP, string>>);
    }

    return null;
};

export const randomString = (
    l: number,
    chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789',
): string => {
    let res = '';
    for (let i = 0; i < l; i++) {
        res += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return res;
};

export const parseCookieValue = (cookieValue?: string): Record<string, string> => {
    if (cookieValue) {
        return cookieValue.split('&').reduce((acc, cur) => {
            const [key, value] = cur.split('=');

            return {
                ...acc,
                [key]: value,
            };
        }, {});
    }

    return {};
};

export const mapObjectKeys = <A extends { [key: string]: unknown }, B extends Partial<{ [key in keyof A]: string }>>(
    obj: A,
    map: B,
) => {
    return Object.keys(obj).reduce((acc, cur) => {
        const key = map[cur] ?? cur;

        return {
            ...acc,
            [key]: obj[cur],
        };
    }, {});
};

/** Только production (sravni.ru) */
export const isProduction =
    (typeof window === 'undefined' ? process.env.ENV : getConfig().publicRuntimeConfig.environment) === 'production';
/** Локальный development, исключая stage */
export const isDevelopment =
    (typeof window === 'undefined' ? process.env.ENV : getConfig().publicRuntimeConfig.environment) === 'development';
export const imageVersion = process.env.IMAGE_VERSION;

export const isNumericString = (n: unknown): boolean => {
    if (typeof n === 'string') {
        const value = parseFloat(n);
        return !isNaN(value) && isFinite(value);
    }

    if (typeof n === 'number') {
        return isFinite(n);
    }

    return false;
};

export const filterNumeric = (n: unknown): number | undefined => {
    const isNumeric = isNumericString(n);
    if (isNumeric && typeof n === 'string') {
        return parseFloat(n);
    }
    if (isNumeric && typeof n === 'number') {
        return n;
    }
    return undefined;
};

export const formatPhone = (phone: string) => {
    if (!phone) {
        return phone;
    }
    const p = phone.replace(/\D/g, '');
    return [
        '+',
        p[0] || '',
        ' ',
        '(',
        p[1] || '',
        p[2] || '',
        p[3] || '',
        ')',
        ' ',
        p[4] || '',
        p[5] || '',
        p[6] || '',
        '-',
        p[7] || '',
        p[8] || '',
        '-',
        p[9] || '',
        p[10] || '',
    ].join('');
};

export const formatPhones = (object: FE.FormData, keys: Array<string>) => {
    for (const key of keys) {
        if (key in object && typeof object[key] === 'string') {
            object[key] = formatPhone(object[key] as string);
        }
    }
};

export const formatPhonesImmutable = (data: FE.FormData, keys: Array<string>): FE.FormData => {
    const output: FE.FormData = JSON.parse(JSON.stringify(data));

    for (const key of keys) {
        if (key in data && typeof data[key] === 'string') {
            output[key] = formatPhone(data[key] as string);
        }
    }

    return output;
};

export const samePhones = (phoneA?: string | null, phoneB?: string | null) => {
    /**
     * Временно считаем, что второй номер из profile-service допустим в пустом виде.
     * @todo Это нужно убрать после того, как PS будет возвращать номера телефонов
     * */
    return !phoneB || Boolean(phoneA && phoneB && phoneA.replace(/\D/g, '') === phoneB.replace(/\D/g, ''));
};

export const convertEmployerType = (employerType: string): string => {
    switch (employerType) {
        case 'OAO':
            return 'PAO';
        case 'ZAO':
            return 'NAO';
        default:
            return employerType;
    }
};

/**
 * @deprecated use convertEmployerType
 */
export const checkEmployerType = (object: FE.FormData) => {
    switch (object[FORM_FIELDS.EMPLOYER_TYPE]) {
        case 'OAO':
            object[FORM_FIELDS.EMPLOYER_TYPE] = 'PAO';
            break;

        case 'ZAO':
            object[FORM_FIELDS.EMPLOYER_TYPE] = 'NAO';
            break;

        default:
    }
};

export const checkEmployerTypeImmutable = (data: FE.FormData) => {
    const output: FE.FormData = JSON.parse(JSON.stringify(data));

    switch (data[FORM_FIELDS.EMPLOYER_TYPE]) {
        case 'OAO':
            output[FORM_FIELDS.EMPLOYER_TYPE] = 'PAO';
            break;

        case 'ZAO':
            output[FORM_FIELDS.EMPLOYER_TYPE] = 'NAO';
            break;

        default:
    }

    return output;
};

export const formatAutoNumber = (v: FE.FieldValue = '') => v.toString().toUpperCase().replace(/_|\s/gm, '');

export const poll = async <T = any>({
    fn,
    validate,
    interval,
    maxAttempts,
}: {
    fn: () => Promise<T>;
    /** if true, resolve result */
    validate: (result: T, attempts: number, prevResult: T | null) => boolean;
    interval: number;
    maxAttempts?: number;
}): Promise<T> => {
    let attempts = 0;
    let prevResult: T | null = null;

    const executePoll = async (resolve: (result: any) => void, reject: (error: any) => void) => {
        let result = prevResult;
        try {
            result = await fn();
            // eslint-disable-next-line no-empty
        } catch (e) {}
        attempts++;

        if (result && validate(result, attempts, prevResult)) {
            return resolve(result);
        }
        if (maxAttempts && attempts === maxAttempts) {
            return resolve(result);
        }
        prevResult = result;
        setTimeout(executePoll, interval, resolve, reject);
    };

    return new Promise(executePoll);
};

export const smartPoll = async <T = any, S = any>({
    fn,
    validate,
    intervalParams,
    store,
    dispatch,
}: {
    fn: (store?: S, dispatch?: Dispatch<any>) => Promise<T>;
    validate: (param: T, prevResult: T | null, dispatch?: Dispatch<any>) => boolean;
    intervalParams: Array<[toInSeconds: number, delaySeconds: number]>;
    store?: S;
    dispatch?: Dispatch<any>;
}): Promise<T> => {
    const startedAt = Date.now();
    let prevResult: T | null = null;

    const getDelay = (): number | null => {
        const durationInSeconds = Math.abs(startedAt - Date.now()) / 1000;
        for (const [toInSeconds, delaySeconds] of intervalParams) {
            if (durationInSeconds < toInSeconds) {
                return delaySeconds * 1000;
            }
        }

        return null;
    };

    const executePoll = async (resolve: (result: any) => void, reject: (error: any) => void) => {
        let result = prevResult;
        try {
            result = await fn(store, dispatch);
            // eslint-disable-next-line no-empty
        } catch (e) {}

        if (result && validate(result, prevResult, dispatch)) {
            return resolve(result);
        }

        prevResult = result;

        const delay = getDelay();
        if (delay) {
            setTimeout(executePoll, delay, resolve, reject);
        }
    };

    return new Promise(executePoll);
};

/**
 * Функция оборачивает Promise для обращения ко внешним ресурсам.
 * Обеспечивает дополнительные попытки в случае неуспеха.
 * @param handler - Функция, возвращающая Promise, который может "упасть"
 * @param options - Опции
 * @param options.count - Количество повторных попыток в случае падения
 * @param options.pause - Время паузы в мс между попытками.
 */
export async function retry<T = any>(handler: () => Promise<T>, { count = 3, pause = 1000 } = {}): Promise<T> {
    count -= 1;
    try {
        return await handler();
    } catch (e) {
        if (count > 0) {
            await wait(pause);
            return retry(handler, { count, pause });
        }
        throw e;
    }
}

export function debounce<P extends Array<unknown>, T extends (...args: P) => unknown>(f: T, ms: number) {
    let isCooldown = false;
    return function (this: T, ...args) {
        if (isCooldown) {
            return;
        }
        f.apply(this, args);
        isCooldown = true;
        setTimeout(() => (isCooldown = false), ms);
    } as T;
}

export function throttle<P extends Array<any>, T extends (...args: P) => any>(f: T, ms: number) {
    let i = 0;
    return function (this: T, ...args) {
        i++;
        setTimeout(() => {
            i--;
            if (!i) {
                f.apply(this, args);
            }
        }, ms);
    } as T;
}

export const isFinalOffer = (offer: Suitable) => Boolean(offer?.advertising?.monetization?.finalDecision);

export const isActiveOffer = (offer: Suitable, fbStatuses?: FbStatues) =>
    Boolean(fbStatuses && offer.id in fbStatuses.statuses);

export const isBankWithActiveOffer = (offer: Suitable, fbStatuses?: FbStatues) =>
    Boolean(fbStatuses && offer.organization.id in fbStatuses.bankStatuses);

export const isOfferAlreadyRequestedWithDelay = (offer: Suitable, fbStatuses?: FbStatues) =>
    Boolean(fbStatuses && offer.id in fbStatuses.delayedStatuses);

export const isOfferAlreadyRequestedFromExactOffers = (offer: Suitable, fbStatuses?: FbStatues) =>
    Boolean(fbStatuses && offer.id in fbStatuses.statuses && Boolean(fbStatuses.statuses[offer.id].findExactOffers));

export const isRejectedOffer = (offer: Suitable, fbStatuses?: FbStatues) =>
    Boolean(
        fbStatuses &&
            offer.id in fbStatuses.statuses &&
            [STATUS_STATES.REJECTED, STATUS_STATES.REFUSED].includes(fbStatuses.statuses[offer.id].status),
    );

export const isOnlineOffer = (offer: Suitable) => Boolean(offer?.advertising?.isMarketplace);

export const isExactConditionOffer = (offer: Suitable) => Boolean(offer?.advertising?.hasExactConditions);

export const isOfferInTheSameLocationRoute = (offer: Suitable | Unsuitable, locationRoute: string) => {
    return Boolean(
        offer.matchingLocations &&
            (!offer.matchingLocations.length || offer.matchingLocations.some((city) => city.route === locationRoute)),
    );
};

export const isReferralOffer = (o: Suitable) => Boolean(o.advertising.monetization.kind === 'referral');

// creditselection-service/src/lib/filterSystem/utils/pmt.ts
const PTM = (ir: number, np: number, pv: number, fv = 0, type: 0 | 1 = 0): number => {
    if (ir === 0) {
        return -(pv + fv) / np;
    }

    const pvif = Math.pow(1 + ir, np);
    let pmt = (-ir * pv * (pvif + fv)) / (pvif - 1);

    if (type === 1) {
        pmt /= 1 + ir;
    }

    return pmt;
};

export const reCalculateCredit = (amount: number, rate: number, periodInMonths: number): FB.ReCalculatedValues => {
    const monthRate = rate / 12;
    const payment = Math.ceil(PTM(monthRate / 100, periodInMonths, amount, 0, 0) * -1);
    const overpayment = payment * periodInMonths - amount;
    return { amount, rate, periodInMonths, payment, overpayment };
};

export const groupStatusesByProductId = (fbStatuses: Array<FB.Status>) => {
    const response: { [key: string]: FB.CleanStatus } = {};
    const now = Date.now();
    for (const s of fbStatuses) {
        const {
            requestId,
            requestType,
            bankId,
            guid,
            AuthLink: authLink,
            offers = [],
            productId,
            isDelayedRequest,
            findExactOffers,
            creditAmount,
            creditTerm,
            rate,
            perMonth,
        } = s;
        const disableDays =
            bankId && bankId.toString() in DISABLE_BANK_OFFERS_TIME_IN_DAYS
                ? DISABLE_BANK_OFFERS_TIME_IN_DAYS[bankId.toString()]
                : DISABLE_BANK_OFFERS_TIME_IN_DAYS.DEFAULT;
        const disableTime = disableDays * 24 * 60 * 60 * 1000;
        const status = s.status || STATUS_STATES.NEW;
        const ts = dateStringToLocalTs(s.date || '', 'Europe/Moscow');
        const source = {
            ts,
            requestId,
            requestType,
            authLink,
            offers,
            status,
            bankId: bankId || '',
            productId,
            guid,
            isDelayedRequest,
            findExactOffers,
            creditAmount,
            creditTerm,
            rate,
            perMonth,
        };
        if (now - source.ts < disableTime && (!response[productId] || response[productId].ts < source.ts)) {
            response[productId] = source;
        } else if (response[productId] && response[productId].ts < source.ts) {
            response[productId] = source;
        }
    }
    return response;
};

export const getDelayedStatuses = (fbStatuses: Array<FB.Status>) => {
    const response: { [key: string]: FB.CleanStatus } = {};
    for (const s of fbStatuses) {
        if (s.isDelayedRequest) {
            response[s.productId] = {
                ts: dateStringToLocalTs(s.date || '', 'Europe/Moscow'),
                requestId: s.requestId,
                requestType: s.requestType,
                authLink: s.AuthLink,
                offers: s.offers || [],
                status: s.status || STATUS_STATES.NEW,
                bankId: s.bankId || '',
                productId: s.productId,
                guid: s.guid,
                isDelayedRequest: s.isDelayedRequest,
                findExactOffers: s.findExactOffers,
                creditAmount: s.creditAmount,
                creditTerm: s.creditTerm,
                rate: s.rate,
                perMonth: s.perMonth,
            };
        }
    }
    return response;
};

/** Определяет, открыта страница самостоятельно или через iFrame */
export const isIframe = () => {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
};

const isCorrectFormDataValue = (v: any) =>
    Boolean(v || typeof v === 'boolean' || (v !== '' && !isNaN(String(v) as unknown as number)));

export const mergeFormData = (prior: FE.FormData, extra: FE.FormData): FE.FormData => {
    const mergedData = { ...prior };
    for (const k of Object.keys(extra)) {
        if (isCorrectFormDataValue(extra[k])) {
            mergedData[k] = extra[k];
        }
    }
    return mergedData;
};

export const isBrowser = typeof window !== 'undefined';

export function removeUndefined<T extends Record<string, any>>(
    obj: T,
    empty: Array<any> = [],
    removeEmptyObjects = false,
    clearableFields: Array<string> = [],
): T {
    const newObj: Record<string, any> = {};
    const emptyValues = [undefined, ...empty];
    Object.entries(obj).forEach(([k, v]) => {
        if (Array.isArray(v)) {
            newObj[k] = obj[k];
        } else if (v === Object(v)) {
            if (removeEmptyObjects) {
                const newSubObj = removeUndefined(v, empty, removeEmptyObjects, clearableFields);
                if (Object.keys(newSubObj).length > 0) {
                    newObj[k] = {
                        ...newSubObj,
                    };
                }
            } else {
                newObj[k] = removeUndefined(v, empty);
            }
        } else if (!emptyValues.includes(v) || clearableFields.includes(k)) {
            newObj[k] = obj[k];
        }
    });
    return newObj as T;
}

export function trimAllFields<T extends Record<string, any>>(obj: T): T {
    (Object.entries(obj) as Array<[keyof T, T[keyof T]]>).forEach(([key, value]) => {
        if (typeof value === 'string') {
            obj[key] = value.trim();
        }
    });
    return obj as T;
}

export const checksum = (value: string) => {
    let chk = 0x12345678;
    const len = value.length;
    for (let i = 0; i < len; i++) {
        chk += value.charCodeAt(i) * (i + 1);
    }

    return (chk & 0xffffffff).toString(16);
};

/** Таймаут для использования внутри Promise.race */
export const promiseTimeout = (ms: number, text?: string): Promise<never> =>
    new Promise((resolve, reject) => {
        setTimeout(() => {
            reject(new Error(text || 'timeout exceed'));
        }, ms);
    });

export const equalIfSet = (first?: string | number | boolean, second?: string | number | boolean) => {
    return !first || !second || first === second;
};

export const hasFormDataConflicts = (
    first: FE.FormData,
    second: FE.FormData,
): { conflict: boolean; fields: Array<string> } => {
    return Object.entries({
        firstName: equalIfSet(first[FORM_FIELDS.FIRST_NAME], second[FORM_FIELDS.FIRST_NAME]),
        lastName: equalIfSet(first[FORM_FIELDS.LAST_NAME], second[FORM_FIELDS.LAST_NAME]),
        patronymic: equalIfSet(first[FORM_FIELDS.MIDDLE_NAME], second[FORM_FIELDS.MIDDLE_NAME]),
        passport: equalIfSet(
            ((first[FORM_FIELDS.PASSPORT_NUMBER] as string) || '').replace(/\D/g, ''),
            ((second[FORM_FIELDS.PASSPORT_NUMBER] as string) || '').replace(/\D/g, ''),
        ),
        birthDate: equalIfSet(first[FORM_FIELDS.BIRTHDAY], second[FORM_FIELDS.BIRTHDAY]),
    }).reduce<{ conflict: boolean; fields: Array<string> }>(
        (acc, [field, isEqual]) => {
            if (!isEqual) {
                acc.fields.push(field);
                acc.conflict = true;
            }
            return acc;
        },
        { conflict: false, fields: [] },
    );
};

export const hasOnlineAndExactConditionOffer = (offers: OfferItems) => {
    type OffersKeyType = keyof OfferItems;

    return Object.keys(offers).reduce(
        (result, key) => {
            if (
                key === 'unsuitable' ||
                key === 'final' ||
                key === 'decisionOffers' ||
                (result.is_mpl && result.is_tu)
            ) {
                return result;
            }

            for (const offer of offers[key as OffersKeyType] || []) {
                if (result.is_mpl && result.is_tu) {
                    return result;
                }

                if (!result.is_mpl) {
                    result.is_mpl = isOnlineOffer(offer as Suitable);
                }

                if (!result.is_tu) {
                    result.is_tu = isExactConditionOffer(offer as Suitable);
                }
            }

            return result;
        },
        {
            is_tu: false,
            is_mpl: false,
        },
    );
};

export const getExperimentsDataFromString = (experiments: string): Record<string, string> => {
    return experiments.split('|').reduce((res, experiment) => {
        const [id, value] = experiment.split('.');
        return {
            ...res,
            [id]: value,
        };
    }, {});
};

export const getOnlyEnabledExperiments = (
    enabled: Array<string>,
    experiments: Record<string, string>,
): Record<string, string> => {
    return enabled.reduce((res, experiment) => {
        if (!experiments[experiment]) {
            return res;
        }
        return {
            ...res,
            [experiment]: experiments[experiment],
        };
    }, {} as Record<string, string>);
};

export const checkIdForCreditCardOffer = (offerId: string, creditCard: CreditProduct) => {
    if (offerId === creditCard._id) {
        return true;
    }
    const [id, paymentSystem] = offerId.split('--');
    return creditCard._id === id && paymentSystem && creditCard.kind && creditCard.kind.includes(paymentSystem);
};

export const checkIdForSuitableOffer = (productIds: string | Array<string>, offer: Suitable): boolean => {
    if (Array.isArray(productIds)) {
        return productIds.some((id) => checkIdForSuitableOffer(id, offer));
    }

    if ([offer.id, offer.newId].includes(productIds)) {
        return true;
    }

    if (offer.category !== 'CreditCard') {
        return false;
    }

    const [targetId, targetPaymentSystem] = productIds.split('--');

    return [offer.id, offer.newId].reduce<boolean>((prev, current) => {
        if (prev) {
            return prev;
        }

        const [currentId, currentPaymentSystem] = current.split('--');

        if (!targetPaymentSystem) {
            return currentId === targetId;
        }

        return Boolean(currentId === targetId) && Boolean(currentPaymentSystem === targetPaymentSystem);
    }, false);
};

export const checkProductId = (productIds: string | Array<string>, offerId: string): boolean => {
    if (Array.isArray(productIds)) {
        return productIds.some((id) => checkProductId(id, offerId));
    }

    const [targetId, targetPaymentSystem] = offerId.split('--');

    const [currentId, currentPaymentSystem] = productIds.split('--');

    if (!currentPaymentSystem) {
        return currentId === targetId;
    }

    return Boolean(currentId === targetId) && Boolean(currentPaymentSystem === targetPaymentSystem);
};
