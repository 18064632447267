var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"528t6s"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/creditselection-frontend";

import * as Sentry from '@sentry/nextjs';
import getConfig from 'next/config';

const { sentryDsn } = getConfig().publicRuntimeConfig;
// eslint-disable-next-line no-undef
const environment = process.env.NODE_ENV || 'production';

Sentry.init({
    dsn: sentryDsn,
    tracesSampleRate: 0.05,
    logLevel: 1,
    environment,
    // enabled: environment === 'production',
    enabled: false,
    ignoreErrors: [
        /.*failed to fetch.*/i,
    ]
});
